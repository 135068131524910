<script>
  import Button, { Label } from "@smui/button";

  import { needOpenRoleChangeDialog } from "~/libs/stores";
</script>

<div class="ecSwitchableInfo">
  <p class="material-icons md-24">info</p>
  <p class="text">
    操作対象のECサイトを切り替えられるユーザーでログインしています。<br />
    <slot name="description" />
  </p>
  <Button
    style="height: 40px; width: 180px; border: 1px solid #2b81d6; font-size: smaller; "
    touch
    on:click={() => {
      needOpenRoleChangeDialog.set(true);
    }}
  >
    <Label>ECサイトを切り替える</Label></Button
  >
</div>

<style lang="scss">
  .ecSwitchableInfo {
    display: flex;
    align-items: center;
    width: 782px;
    padding: 5px 3px;
    background-color: #f3f8fc;
    font-weight: 700;
    color: #242424;
    margin: 10px 0 0 0;
    border-radius: 5px;

    .material-icons {
      color: #2b81d6;
      margin: 0 12px;
    }

    .text {
      font-size: 13px;
      line-height: 1.4;
      color: #242424;
      min-width: 545px;
    }
  }
</style>
