<script>
  import Button, { Label } from "@smui/button";
  import MenuSurface from "@smui/menu-surface";
  import { createEventDispatcher, getContext, onDestroy } from "svelte";
  import { push } from "svelte-spa-router";

  import logo from "~/assets/images/title.png";
  import AccountCircleIcon from "~/components/icons/account-circle.svelte";
  import ChevronDownIcon from "~/components/icons/chevron-down.svelte";
  import { CONTEXT_KEY_USER } from "~/libs/constants";
  import { activeContent, needOpenRoleChangeDialog } from "~/libs/stores";
  import PasswordChange from "~/pages/Main/PasswordChange.svelte";
  import RoleChangeDialog from "~/pages/Main/RoleChangeDialog.svelte";
  import UserProfile from "~/pages/Main/UserProfile.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  const dispatch = createEventDispatcher();

  const showNeedOpenRoleChangeDialogUnsubscribe =
    needOpenRoleChangeDialog.subscribe((isNeed) => {
      if (isNeed) {
        openRoleChangeDialog = true;
        needOpenRoleChangeDialog.set(false);
      }
    });

  /** @type {number} */
  const globalNaviThreshold = 651;

  /** @type {import("@smui/menu-surface").default} */
  let globalNavi;

  /** @type {boolean} */
  let openProfileDialog = false;

  /** @type {boolean} */
  let openPwChangeDialog = false;

  /** @type {boolean} */
  let openRoleChangeDialog = false;

  /** @type {boolean} */
  let menuOpened;

  /** @type {boolean} */
  let isNarrowWindow = window.innerWidth < globalNaviThreshold ? true : false;

  /** @type {string} */
  let currentCompanyName = userContext.getCurrentCompanyName();

  onDestroy(() => {
    showNeedOpenRoleChangeDialogUnsubscribe();
  });

  function logout() {
    userContext.erase();
    push("/");
  }

  function openMenu() {
    menuOpened = true;
    dispatch("menu", { menuOpened: menuOpened });
  }
  function closeMenu() {
    menuOpened = false;
    dispatch("menu", { menuOpened: menuOpened });
  }

  function resizeWindow() {
    if (window.innerWidth < globalNaviThreshold) {
      isNarrowWindow = true;
    } else {
      isNarrowWindow = false;
    }
  }

  function reloadCurrentCompanyName() {
    currentCompanyName = userContext.getCurrentCompanyName();
  }

  window.onresize = resizeWindow;
</script>

<UserProfile
  bind:open={openProfileDialog}
  on:changePassword={() => (openPwChangeDialog = true)}
/>

{#key openPwChangeDialog}
  <PasswordChange bind:open={openPwChangeDialog} />
{/key}

{#key openRoleChangeDialog}
  <RoleChangeDialog
    bind:open={openRoleChangeDialog}
    {reloadCurrentCompanyName}
  />
{/key}

<div class="header {menuOpened ? 'open' : ''}">
  <div class="menuCloseArea">
    <Button class="menuCloser" on:click={closeMenu}>
      <span class="material-icons md-48"> close </span>
    </Button>
  </div>
  <Button class="menuOpener" on:click={openMenu}>
    <span class="material-icons"> menu </span>
  </Button>
  <label class="logo">
    <img style="height: 60px;" src={logo} alt="logo" />
    <input
      type="button"
      id="logoBtn"
      on:click={() => {
        activeContent.set("Home");
      }}
    />
  </label>
  <div class="vline" />
  {#if userContext.loginUser?.displayName && userContext.loginUser?.companyName}
    <div class="loginInfoArea">
      <div class="loginInfoInner">
        {#if userContext.loginUser?.switchableCompanies?.length > 1}
          <Button
            touch
            variant="unelevated"
            style=" color: #1E88E5; background-color: #E3F2FD;"
            on:click={() => {
              openRoleChangeDialog = true;
              globalNavi.setOpen(false);
            }}
          >
            <Label>{currentCompanyName}</Label>
          </Button>
        {/if}
        <div>
          <span class="userName">{userContext.loginUser.displayName} 様</span>
          <div style="height:3px" />
          <span class="userCompany">{userContext.loginUser.companyName}</span>
        </div>
      </div>
    </div>
  {/if}
  <div class="globalNaviArea">
    <div class="glovalNavi">
      <Button on:click={() => globalNavi.setOpen(true)}>
        {#if isNarrowWindow}
          <AccountCircleIcon />
        {:else}
          <ChevronDownIcon />
        {/if}
      </Button>
      <MenuSurface
        bind:this={globalNavi}
        anchorCorner="BOTTOM_LEFT"
        style="margin-top: 10px; width: 280px; height: auto; box-shadow: 0 2px 1px #ddd;"
      >
        <div class="userInfoArea">
          <div class="topBlock">
            <div class="accountIcon">
              <AccountCircleIcon />
            </div>
            <div class="userInfo">
              <span class="userInfoName"
                >{userContext.loginUser?.displayName} 様</span
              >
              <div style="height:3px" />
              <span class="userInfoCompany"
                >{userContext.loginUser?.companyName}</span
              >
            </div>
          </div>
          <div class="middleBlock">
            <Button
              touch
              variant="unelevated"
              style="width: 100%;"
              on:click={() => {
                openProfileDialog = true;
                globalNavi.setOpen(false);
              }}
            >
              <Label>プロフィール</Label>
            </Button>
          </div>
        </div>
        <div class="buttonListArea">
          {#if userContext.loginUser?.switchableCompanies?.length > 1}
            <Button
              style="color: black;"
              on:click={() => {
                openRoleChangeDialog = true;
                globalNavi.setOpen(false);
              }}
            >
              <Label
                style="display: flex; align-items: center; justify-content: left; gap: 10px;"
                ><span class="material-icons button-icons">
                  autorenew
                </span><span
                  >操作対象のECサイトを切替
                  <p class="currentCompanyInfo">
                    <br />(現在：{userContext.getCurrentCompanyName()})
                  </p></span
                ></Label
              >
            </Button>
            <br />
          {/if}
          <Button
            style="color: black;"
            on:click={() => {
              openPwChangeDialog = true;
              globalNavi.setOpen(false);
            }}
          >
            <Label
              style="display: flex; align-items: center; justify-content: left; gap: 10px;"
              ><span class="material-icons button-icons">
                vpn_key
              </span>パスワード変更</Label
            >
          </Button>
          <br />
          <Button
            style="color: black;"
            on:click={() => {
              globalNavi = null;
              logout();
            }}
          >
            <Label
              style="display: flex; align-items: center; justify-content: left; gap: 10px;"
              ><span class="material-icons button-icons">
                logout
              </span>ログアウト</Label
            >
          </Button>
        </div>
      </MenuSurface>
    </div>
  </div>
</div>

<style lang="scss">
  .header {
    height: 80px;
    width: 100%;
    min-width: 650px;
    background-color: rgba(255, 255, 255);
    box-sizing: border-box;
    border-width: 1px;
    border-color: rgb(204, 205, 206);
    border-bottom-style: solid;
    display: flex;
    align-items: center;
    position: fixed;
    transition: 0.5s;
    z-index: 5;
  }
  .logo {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #logoBtn {
    position: absolute;
    opacity: 0;
  }
  .vline {
    margin: 20px 0px;
    background-color: rgb(204, 205, 206);
    min-width: 1.2px;
    height: 40px;
  }
  .loginInfoArea {
    position: absolute;
    right: 60px;
    text-align: right;
  }
  .loginInfoInner {
    display: flex;
    gap: 16px;
    align-items: center;
  }
  .userCompany {
    font-size: small;
  }

  /* グローバルナビ */
  .globalNaviArea {
    position: absolute;
    right: 0;
  }
  .glovalNavi {
    text-align: right;

    :global(.svg-icons-chevron-down) {
      width: 25px;
      height: 25px;
    }
    > :global(.mdc-button) {
      padding: 0;
      height: 60px;
    }
    :global(.mdc-button__ripple::before) {
      background-color: white;
    }
    :global(.mdc-button__ripple::after) {
      background-color: white;
    }
    :global(.svg-icons-account-circle) {
      width: 60px;
      height: 60px;
      fill: rgb(70, 70, 70);
    }
    :global(.mdc-menu-surface--open) {
      box-shadow: none;
      border-width: 1px;
      border-color: rgb(241, 239, 239);
      border-left-style: solid;
      border-bottom-style: solid;
    }
    :global(.mdc-menu-surface--animating-closed) {
      box-shadow: none;
      border-width: 1px;
      border-color: rgb(241, 239, 239);
      border-left-style: solid;
      border-bottom-style: solid;
    }
  }
  .userInfoArea {
    text-align: left;
    display: column;
    border-width: 1px;
    border-color: rgb(204, 205, 206);
    border-bottom-style: solid;
  }
  .topBlock {
    padding: 4px 20px 0;
    display: flex;
  }
  .middleBlock {
    padding: 0 26px 10px;
    display: flex;
    justify-content: center;
  }
  .userInfo {
    display: column;
    margin: 12px 0 0 10px;
  }
  .userInfoName {
    line-height: 19px;
  }
  .userInfoCompany {
    font-size: small;
  }
  .buttonListArea {
    display: column;
    text-align: left;
    padding: 10px 20px;
  }
  .button-icons {
    font-size: 20px;
  }

  @media screen and (min-width: 1172px) {
    .header {
      :global(.mdc-button.menuOpener) {
        display: none;
      }
    }
    .menuCloseArea {
      display: none;
    }
    .logo {
      width: 298px;
    }
    .currentCompanyInfo {
      display: none;
    }
  }

  @media screen and (max-width: 1171px) {
    .header {
      left: -298px;
      width: calc(100% + 298px);
      min-width: auto;
    }
    .header.open {
      left: 0;
    }
    .menuCloseArea {
      background-color: #376297;
      border-bottom: 1px solid #ffffffaa;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
      left: 0;
      transition: 0.5s;
      height: 80px;
      width: 298px;
      z-index: 100;

      :global(.mdc-button.menuCloser) {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        width: 60px;
        height: 60px;
        min-width: 60px;
      }
      :global(.mdc-button.menuCloser .material-icons) {
        color: #fff;
      }
    }
    .header {
      :global(.mdc-button.menuOpener) {
        background-color: transparent;
        border: 1px solid #376297;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        width: 40px;
        height: 40px;
        min-width: 40px;
      }
      :global(.mdc-button.menuOpener:hover) {
        cursor: pointer;
      }
      :global(.mdc-button.menuOpener .material-icons) {
        color: #376297;
        font-size: 28px;
      }
    }
    .logo {
      width: 248px;
    }
    .currentCompanyInfo {
      display: none;
    }
  }

  @media screen and (max-width: 650px) {
    .loginInfoArea {
      display: none;
    }
    .currentCompanyInfo {
      display: block;
      font-size: small;
      line-height: 0.5;
      text-align: left;
    }
  }
</style>
